.sft-agreement-container {
  margin: 12px 0;

  .row {
    margin-bottom: 12px;
  }

  .sft-agree-all-checkbox {
    padding-bottom: 12px;
    border-bottom: 1px solid #c2cad5;

    label {
      font-weight: bold !important;
      color: $body-color;
    }
  }

  label {
    font-size: 13px !important;
    font-weight: normal !important;
    color: #555a5f;
    margin-bottom: 0;
  }

  input {
    margin-right: 12px;
  }
}