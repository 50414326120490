@import "main";
@import "agreementCheckboxes";

.modal-dialog .modal-content {
  box-shadow: 0 8px 24px rgba(#7f858e, .36);
  border-radius: 2px;
  border-color: #c2cad5;


  .modal-header {
    border-bottom: 1px solid #e8edf4;

    .close {
      color: #4f5359;
      opacity: 1;

      &:not(:disabled):not(.disabled) {
        &:hover {
          color: #1e1f21;
          opacity: 1;
        }
      }
    }

    h4 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .modal-body {
    p {
      font-size: 14px;
    }

    label {
      font-weight: 600;
      line-height: 1;
    }
  }

  .modal-footer {
    padding: 12px 16px;
    border-top: 1px solid #e8edf4;
  }
}

.modal#findEmailModal {
  p {
    word-break: keep-all;
  }
}

.modal.sft-find-password-modal {
  .sft-forgot-email {
    text-align: right;

    a {
      color: #595e67;
      font-size: 13px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

header {
  margin-top: 20vh;

  h3 {
    margin-bottom: 32px;
    font-size: 2rem !important;
  }
}

.sft-form {
  margin-bottom: 36px;

  input.form-control {
    font-size: 18px;
  }

  button.btn {
    padding: 12px 16px !important;
  }

  label:not(.custom-control-label) {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
  }

  .custom-checkbox {
    .custom-control-label {
      font-size: 14px;
      line-height: 1;

      &::before {
        top: 0;
        border-radius: 0;
        width: 14px;
        height: 14px;
      }

      &::before {
        border-color: #c2cad5;
      }
    }

    .custom-control-input {
      &:focus~.custom-control-label::before {
        box-shadow: none;
      }

      &:checked {
        &~.custom-control-label::before {
          border-color: #0062c9;
          background-color: #0062c9;
        }

        &~.custom-control-label::after {
          top: 1px;
          left: -1.2rem;
          width: 4.5px;
          height: 8.5px;
          border: solid white;
          border-width: 0 1.5px 1.5px 0;
          transform: rotate(45deg);
          background-image: none !important;
        }
      }
    }
  }

  .sft-email-label-and-find,
  .sft-password-label-and-find {
    display: flex;

    button {
      font-size: 12px;
    }
  }

  .form-divider {
    margin: 16px 0;
    position: relative;
    text-align: center;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      border-top: 1px solid #c2cad5;
    }

    &-text {
      display: inline-block;
      position: relative;
      padding: 0 8px;
      background: white;
    }
  }

  .sft-buttons-container {
    a img {
      vertical-align: text-bottom;
    }
  }
}

.sft-registers {
  span, a {
    font-size: 14px !important;
  }
}

.alert {
  padding: 8px 16px;
  display: flex;

  div {
    margin: 6px 0 6px 12px;
    font-size: 14px;
    word-break: keep-all;
  }

  &.alert-danger {
    border: 1px solid #bf0920;
    background-color: #faf1f2;
    color: #950013;
    box-shadow: 0 4px 8px rgba(#bf0920, .15);

    ul {
      padding-left: 1rem;
      margin-bottom: 0;

      li {
        margin-top: 4px;

        a {
          color: #950013;
          text-decoration: underline;
        }
      }
    }

    &::before {
      content: "\f06a";
    }
  }

  &.alert-success {
    border: 1px solid #01853b;
    background-color: #eefaf4;
    color: #005d16;
    box-shadow: 0 4px 8px rgba(#01853b, .15);

    a {
      text-decoration: underline;
      color: #005d16;

      &:hover {
        color: #00380c;
      }
    }

    &::before {
      content: "\f058";
    }
  }

  &::before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    font-size: 20px;
    vertical-align: middle;
  }
}

.popover {
  max-width: 350px;
  padding: 8px;

  ul {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 1rem;

    li {
      margin-bottom: 8px;
      word-break: keep-all;

      a:hover {
        text-decoration: underline;
      }
    }
  }
}