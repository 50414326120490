@import "../index/common/main";

/* Override Bootstrap - cannot override via sass variables as we load bootstrap from cdn - more performant */
.modal-backdrop {
  background-color: rgba(#27282e, .7);
}

#accounts-header {
  padding: 24px;
  position: fixed !important;
  top: 0;
  width: 100%;
  height: 64px;
  z-index: 999;

  span, a {
    font-size: 14px;
  }
}

button.btn, a.btn {
  padding: 8px 16px !important;
  font-size: 14px !important;
  line-height: inherit !important;
  border-radius: 2px !important;

  &.btn-tertiary {
    border: 1px solid #c2cad5;
    background-color: white;
    color: #27282e;
    font-weight: 400;

    &:hover, &:focus, &:active {
      border-color: #a5adb8;
      background-color: #f4f7fb;
      color: #1e1f21;
    }
  }

  &.btn-primary {
    border: 1px solid #0575e6;

    &:hover {
      background-color: #2789ed;
      border-color: #2789ed;
    }

    &:focus {
      box-shadow: 0 0 4px 2px rgba($primary, .25);
    }

    &:active {
      background-color: #0062c9;
      border-color: #0062c9;
    }
  }
}

.sft-accounts-sub-page-container {
  padding-top: 24vh;

  .sft-accounts-sub-page-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(216, 223, 232, .8);
  }
}

button.btn-flat, a.btn-flat {
  &:hover {
    color: #1e1f21;
    text-decoration: underline;
  }
}

input.form-control {
  padding: 8px 16px;
  border: 1px solid #c2cad5;
  border-radius: 2px;
  font-size: 14px;
  box-shadow: none;

  &:focus {
    border-color: $primary;
    box-shadow: none;
  }
}

footer {
  margin-top: 36px;

  p {
    color: #a5adb8;
    font-size: 12px;
  }
}